<template>
    <div v-if="isOpen" class="error-modal">
      <div class="error-modal-content">
        <slot></slot>
        <button @click="closeModal" class="error-modal-button">OK</button>
      </div>
    </div>
  </template>
  
  <script>
  //import { ref } from 'vue';
  
  export default {
    props: {
      isOpen: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      closeModal() {
        this.$emit('close');
      }
    }
  };
  </script>
  
  <style scoped>
  .error-modal {
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .error-modal-content {
    background-color: #fff;
    border-radius: 5px;
    width: 500px;
    padding: 20px;
    text-align: center;
  }
  
  .error-modal-button {
    background-color: #ff4d4d;
    border: none;
    color: #fff;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    height: auto;
    line-height: 100%;
  }
  </style>