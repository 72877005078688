<template>
  <div id="app">
    <!-- Ez a másik komponensből futtatható HIBA üzenet modal ablak.
    Felparaméterezve a custom üzenetekhez. -->
    <div>
      <ErrorModal :isOpen="isErrorModalOpen" @close="closeErrorModal">
        <h3>{{ errorBoxhead }}</h3>
        <p>{{ errorBoxbody }}</p>
      </ErrorModal>
    </div>

  

    <form @submit.prevent="signInButtonPressed">
      <div class="wrapper">
        <div>
          <div v-for="key in count" :key="key">
            <!-- <input type="text" v-model="values['dynamic-field-'+key]" placeholder="Dinamikus mező" :id="key" > -->
            <div class="offer-row">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  border-bottom: 3px dotted #cccccc;
                "
              >
                <span class="offer-row-ledger"># {{ key }}. tétel</span>

                <div v-if="key == count && count > 1">
                  <span
                    >utolsó tétel törlése
                    <a
                      href="#"
                      id="remove_fields"
                      @click="remove(key)"
                      class="btn-delete"
                      >x</a
                    >
                  </span>
                </div>
              </div>

              <div class="offer-flex-parent">
                <div class="offer-flex-select">
                  <h4>Nyílászáró:</h4>

                  <select
                    :name="'offer-flex-select-' + key"
                    :id="'offer-flex-select-' + key"
                    class="offer-select"
                    @change="selectedWindow($event, key)"
                  >
                    <template v-for="option in products">
                      <optgroup
                        v-if="option.group"
                        :label="option.group"
                        :key="option.group"
                      >
                        <option
                          v-for="product in option.items"
                          :value="product.id"
                          :key="product.id"
                          ref="targetRef"
                        >
                          {{ product.name }}
                        </option>
                      </optgroup>
                    </template>
                  </select>
                  <div class="offer-short-descr">
                    {{ message[key] }}
                  </div>
                </div>
                <div class="offer-flex-item">
                  <img :src="messagePic[key]" />
                </div>
              </div>

              <div class="offer-flex-parent">
                <div class="offer-flex-item">
                  <h4>
                    Szélesség:
                    <span v-if="selectedRows[key].widthMin > 0"
                      >({{ selectedRows[key].widthMin }}-{{
                        selectedRows[key].widthMax
                      }}
                      cm)</span
                    >
                  </h4>
                  <input
                    :disabled="disabledValues"
                    class="offer-width"
                    :id="'dynamic-width-' + key"
                    v-model="values['dynamic-width-' + key]"
                    @change="selectedData($event, key, 'selectedWidth')"
                    type="number"
                    :min="selectedRows[key].widthMin"
                    :max="selectedRows[key].widthMax"
                    step="1"
                    placeholder="cm"
                    @focusout="
                      minMaxInputCheck(
                        selectedRows[key].widthMin,
                        selectedRows[key].widthMax,
                        values['dynamic-width-' + key],
                        'dynamic-width-' + key
                      )
                    "
                  />
                </div>

                <div class="offer-flex-item">
                  <h4>
                    Magasság:
                    <span v-if="selectedRows[key].heightMin > 0"
                      >({{ selectedRows[key].heightMin }}-{{
                        selectedRows[key].heightMax
                      }}
                      cm)</span
                    >
                  </h4>
                  <input
                    :disabled="disabledValues"
                    class="offer-height"
                    :id="'dynamic-height-' + key"
                    v-model="values['dynamic-height-' + key]"
                    @change="selectedData($event, key, 'selectedHeight')"
                    type="number"
                    :min="selectedRows[key].heightMin"
                    :max="selectedRows[key].heightMax"
                    step="1"
                    placeholder="cm"
                    @focusout="
                      minMaxInputCheck(
                        selectedRows[key].heightMin,
                        selectedRows[key].heightMax,
                        values['dynamic-height-' + key],
                        'dynamic-height-' + key
                      )
                    "
                  />
                </div>

                <div class="offer-flex-item">
                  <h4>Mennyiség:</h4>
                  <input
                    :disabled="disabledValues"
                    class="offer-quantity"
                    :id="'dynamic-quantity-' + key"
                    v-model="values['dynamic-quantity-' + key]"
                    @change="selectedData($event, key, 'selectedQuantity')"
                    type="number"
                    :min="1"
                    :max="100"
                    step="1"
                    placeholder="db."
                  />
                </div>
              </div>

              <div class="offer-flex-parent">
                <div class="offer-flex-item">
                  <h4>Árnyékolás:</h4>
                  <select
                    :disabled="disabledValues"
                    :id="'dynamic-shading-' + key"
                    v-model="values['dynamic-shading-' + key]"
                    @change="selectedData($event, key, 'selectedShading')"
                    class="shadings-select"
                  >
                    <template v-for="option in shadings">
                      <optgroup
                        v-if="option.group"
                        :label="option.group"
                        :key="option.group"
                      >
                        <option
                          v-for="shading in option.items"
                          :value="shading.name"
                          :key="shading.id"
                        >
                          {{ shading.name }}
                        </option>
                      </optgroup>
                    </template>
                  </select>
                </div>

                <div class="offer-flex-item">
                  <h4>Párkány:</h4>
                  <select
                    :disabled="disabledValues"
                    :id="'dynamic-ledges-' + key"
                    v-model="values['dynamic-ledges-' + key]"
                    @change="selectedData($event, key, 'selectedLedge')"
                    class="ledges-select"
                  >
                    <option
                      v-for="ledge in ledges"
                      :value="ledge.name"
                      :key="ledge.id"
                    >
                      {{ ledge.name }}
                    </option>
                  </select>
                </div>

                <div class="offer-flex-item">
                  <h4>Beépítés:</h4>
                  <select
                    :disabled="disabledValues"
                    :id="'dynamic-installation-' + key"
                    v-model="values['dynamic-installation-' + key]"
                    @change="selectedData($event, key, 'selectedInstallation')"
                    class="installation-select"
                  >
                    <option
                      v-for="installation in installations"
                      :value="installation.name"
                      :key="installation.id"
                    >
                      {{ installation.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="controls">
          <a href="#" id="add_more_fields" @click="add" class="btn-add">
            + Újabb tétel hozzáadása</a
          >

          <template v-if="count > 1">
            <a
              href="#"
              id="remove_fields"
              @click="remove(count)"
              class="btn-remove"
            >
              - Utolsó tétel törlése</a
            >
          </template>
        </div>
      </div>

      <div ref="myElement" id="myElement"></div>

      <h3>Az ajánlatot kérő adatai</h3>

      <div class="wrapper">
        <div class="offer-flex-parent">
          <div class="offer-flex-personal">
            <h4>Ajánlatkérő neve (*kötelező):</h4>

            <input
              v-model="values['formUserName']"
              id="formUserName"
              @change="userCheck(3, 'formUserName')"
              class="offer-personal"
              type="text"
              placeholder="Kérjük adja meg a teljes nevét!"
            />
          </div>

          <div class="offer-flex-personal">
            <h4>Email címe (*kötelező):</h4>

            <input
              v-model="values['formUserEmail']"
              id="formUserEmail"
              @change="userCheck(6, 'formUserEmail')"
              class="offer-personal"
              type="email"
              placeholder="Kérjük adja meg az email címét!"
            />
          </div>
        </div>

        <div class="offer-flex-parent">
          <div class="offer-flex-personal">
            <h4>Telefonszáma (*kötelező):</h4>

            <input
              v-model="values['formUserPhone']"
              id="formUserPhone"
              class="offer-personal"
              @change="userCheck(6, 'formUserPhone')"
              type="text"
              placeholder="Kérjük adja meg a telefonszámát!"
            />
          </div>

          <div class="offer-flex-personal">
            <h4>Beépítés helye (város) (*kötelező):</h4>

            <input
              v-model="values['formUserCity']"
              id="formUserCity"
              @change="userCheck(2, 'formUserCity')"
              class="offer-personal"
              type="text"
              placeholder="Kérjük adja meg a kivitelezés településének nevét!"
            />
          </div>
        </div>

        <div class="offer-flex-parent">
          <div class="offer-flex-personal-message">
            <h4>Üzenet:</h4>

            <textarea
              v-model="values['formUserMessage']"
              class="offer-personal"
              type="text"
              placeholder="Amennyiben az ajánlatkérőben nem találta meg amit szeretne, kérjük itt írja le röviden! Kollégánk hamarosan keresni fogja, hogy a megfelelő ajánlatot tudjuk Önnek adni."
            />
          </div>
        </div>
        <p>
          <!-- <button class="btn" @click="submit">Ajánlatkérés elküldése</button>  -->
          <button class="btn" @click="signUpButtonPressed">
            Kérem az ajánlatot!
          </button>
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import ErrorModal from "./ErrorModal.vue";
import axios from "axios";

//import { reactive } from 'vue'

export default {
  name: "App",
  components: {
    ErrorModal,
  },

  data: function () {
    return {
      //postDataUrl: "https://pongiablak.powergent.com/feeder-pilon-noprice.php",
      //finishUrlAfterOrder: "https://pilonablak.hu/koszonjuk",
      //domainPicUrl: "https://ajanlatkero.powergent.com/pilon/pics/",


      //postDataUrl: "https://pongiablak.powergent.com/feeder-dunakanyar-noprice.php",
      //finishUrlAfterOrder: "https://dunakanyarablak.hu/thank-you-finish",
      //domainPicUrl: "https://ajanlatkero.powergent.com/dunakanyarablak/pics/",

      //postDataUrl: "https://pongiablak.powergent.com/feeder-iso-noprice.php",
      //finishUrlAfterOrder: "https://www.isoablak.hu/send.php",
      //domainPicUrl: "https://ajanlatkero.powergent.com/isoablak/pics/",

      //postDataUrl: "https://pongiablak.powergent.com/feeder-aluminiumnyilaszaro-noprice.php",
      //finishUrlAfterOrder: "https://aluminiumnyilaszaro.hu/koszonjuk",
      //domainPicUrl: "https://ajanlatkero.powergent.com/aluminiumnyilaszaro/pics/",

      //postDataUrl: "https://pongiablak.powergent.com/feeder-biaablak-noprice.php",
      //finishUrlAfterOrder: "https://biaablak.hu/koszonjuk",
      //domainPicUrl: "https://ajanlatkero.powergent.com/biaablak/pics/",

      //postDataUrl: "https://pongiablak.powergent.com/feeder-kommerlingablak-noprice.php",
      //finishUrlAfterOrder: "https://kommerlingablak.hu/koszonjuk",
      
     // postDataUrl: "https://pongiablak.powergent.com/feeder-pongiablak-noprice.php",
     // finishUrlAfterOrder: "https://pongiablak.hu/koszonet",
     // domainPicUrl: "https://ajanlatkero.powergent.com/pongiablak/pics/",


     // postDataUrl: "https://pongiablak.powergent.com/feeder-rekord-noprice.php",
     // finishUrlAfterOrder: "https://www.rekordnyilaszaro.hu/koszonjuk",
     // domainPicUrl: "https://ajanlatkero.powergent.com/rekord/pics/",

     // postDataUrl: "https://pongiablak.powergent.com/feeder-ablakanapra-noprice.php",
     // finishUrlAfterOrder: "https://www.ablakanapra.hu/koszonjuk",
     // domainPicUrl: "https://ajanlatkero.powergent.com/ablakanapra/pics/",

     //postDataUrl: "https://pongiablak.powergent.com/feeder-iso-tesztprice.php",
     //finishUrlAfterOrder: "https://ajanlatkero.powergent.com/isoteszt/koszonjuk.php",
     //domainPicUrl: "https://ajanlatkero.powergent.com/isoteszt/pics/",

     // postDataUrl: "https://pongiablak.powergent.com/feeder-iso-price.php",
     // finishUrlAfterOrder: "https://www.isoablak.hu/send.php",
     // domainPicUrl: "https://ajanlatkero.powergent.com/isoablak/pics/",
     
      //postDataUrl: "https://pongiablak.powergent.com/feeder-fenster-price.php",
      //finishUrlAfterOrder: "https://fensterplast.hu/koszonjuk-ajanlatkereset",

      postDataUrl: "https://pongiablak.powergent.com/feeder-kommerlingablak-price.php",
      finishUrlAfterOrder: "https://kommerlingablak.hu/koszonjuk",
      domainPicUrl: "https://ajanlatkero.powergent.com/kommerlingablak/pics/",
     


      str : "",
      isErrorModalOpen: false, // Custom Modal Box alapból rejtve.
      noPicUrl: "https://powergent.com/none-window.gif", // Amikor nincs kép, ezt a GIF-et tölti be.
      defaultQuantity: 1, // Alapértelmezetten ennyi az ajánlatkérőben megjelenő mennyiség a termékekhez.
      disabledValues: true, // Alapértelmezetten nem lehet szerkeszteni a mennyiségi adatokat, amíg nincs kiválasztva termék.
      message: {}, //A kiválasztott termékek leírásának gyűjtőtömbje.
      messagePic: [this.noPicUrl, this.noPicUrl], // A kiválasztott termékek gifjeinek gyűjtőtömbje.
      checkSelectedRowValue: 0, // Változó a választott sorok ellenőrzéséhez. Ha ez > 0 akkor el lehet küldeni a formot!
      count: 1, // A dinamikusan növelt kosár számlálója.
      values: {},
      productData: [
        {
          id: "0",
          name: "Válasszon ablakot!",
          widthMin: "0",
          widthMax: "0",
          heightMin: "0",
          heightMax: "0",
          picture: "",
          description: "",
        },

        {
          id: "1",
          name: "Fix ablak",
          widthMin: "30",
          widthMax: "240",
          heightMin: "30",
          heightMax: "240",
          picture: "ablak-fix.gif",
          description:
            "fix ablak, tokba van üvegezve, nem nyitható, nincs rajta szárny.",
        },
        {
          id: "2",
          name: "Bukó ablak felső kilinccsel",
          widthMin: "50",
          widthMax: "150",
          heightMin: "50",
          heightMax: "90",
          picture: "ablak-buko.gif",
          description:
            "egykilincses bukó ablak, nem nyitható, csak buktatható, a kilincs fent középen helyezkedik el. Tisztításhoz a bukóollók kiakasztásával teljesen lenyítható, így könnyen hozzáférhető.",
        },
        {
          id: "3",
          name: "Két kilincses bukó ablak",
          widthMin: "60",
          widthMax: "150",
          heightMin: "100",
          heightMax: "240",
          picture: "ablak-buko-ketkilincses.gif",
          description:
            "kétkilincses bukó ablak, nem nyitható, csak buktatható, a kilincsek kétoldalt helyezkednek el. Tisztításhoz a bukóollók kiakasztásával teljesen lenyítható, így könnyen hozzáférhető",
        },
        {
          id: "4",
          name: "Bukó-nyíló ablak",
          widthMin: "50",
          widthMax: "150",
          heightMin: "50",
          heightMax: "240",
          picture: "ablak-buko-nyilo.gif",
          description: "bukó és nyíló funkcióval rendelkező ablak",
        },
        {
          id: "5",
          name: "Váltószárnyas nyíló + bukó-nyíló",
          widthMin: "90",
          widthMax: "200",
          heightMin: "90",
          heightMax: "240",
          picture: "ablak-valtoszarnyas-buko-nyilo-nyilo.gif",
          description:
            "váltószárnyas nyíló és bukó-nyíló szárnnyal, először a bukó-nyíló szárny nyílik és csak utána a nyíló szárny, a két szárny közt nincs osztás",
        },
        {
          id: "6",
          name: "Tokosztott bukó-nyíló + bukó-nyíló",
          widthMin: "90",
          widthMax: "240",
          heightMin: "90",
          heightMax: "190",
          picture: "ablak-tokosztott-buko-nyilo-buko-nyilo.gif",
          description:
            "tokosztott szerkezet, egy tokban két bukó-nyíló ablak van, ha mindkét szárnyat kinyitjuk, középen látható egy tokosztás",
        },
        {
          id: "7",
          name: "Bukó-nyíló erkélyajtó",
          widthMin: "70",
          widthMax: "100",
          heightMin: "200",
          heightMax: "240",
          picture: "erkelyajto-buko-nyilo.gif",
          description:
            "bukó-nyíló erkélyajtó, nyíló szárnyal és bukó funkcióval, 7 cm-es tokküszöbbel, egyoldali kilinccsel",
        },

        {
          id: "8",
          name: "Váltószárnyas nyíló + bukó-nyíló erkélyajtó",
          widthMin: "120",
          widthMax: "200",
          heightMin: "200",
          heightMax: "240",
          picture: "erkelyajto-valtoszarnyas-buko-nyilo-nyilo.gif",
          description:
            "váltószárnyas erkélyajtó nyíló és bukónyíló szárnnyal, először a bukó-nyíló szárny nyílik és csak utána a nyíló szárny, a két szárny közt nincs osztás",
        },

        {
          id: "9",
          name: "Egyszárnyú toló-bukó erkélyajtó",
          widthMin: "180",
          widthMax: "320",
          heightMin: "200",
          heightMax: "240",
          picture: "erkelyajto-buko-tolo.gif",
          description:
            "egyszárnyú toló-bukó erkélyajtó, 7 cm-es tokküszöbbel, egyoldali kilinccsel, buktatható szárnnyal",
        },

        {
          id: "10",
          name: "Kétszárnyú toló-bukó erkélyajtó",
          widthMin: "360",
          widthMax: "560",
          heightMin: "200",
          heightMax: "240",
          picture: "",
          description:
            "kétszárnyú toló-bukó erkélyajtó, 7 cm-es tokküszöbbel, szárnyanként egy-egy kilinccsel, fő szárny buktatható és tolható, másodszárny csak tolható",
        },

        {
          id: "11",
          name: "Befelé nyíló bejárati ajtó, teli üvegezéssel",
          widthMin: "80",
          widthMax: "110",
          heightMin: "200",
          heightMax: "240",
          picture: "bejarati-ajto-uveges.jpg",
          description:
            "befele nyíló üveges bejárati ajtó, 2cm alu küszöbbel, 5 pontos zárral",
        },

        {
          id: "12",
          name: "Befelé nyíló, kétszárnyú bejárati ajtó, teli üvegezéssel",
          widthMin: "120",
          widthMax: "220",
          heightMin: "180",
          heightMax: "240",
          picture: "bejarati-ajto-valtoszarnyas-uveges.jpg",
          description:
            "befele nyíló üveges váltószárnyas bejárati ajtó, 2cm alu küszöbbel, 5 pontos zárral, előszőr a főszárny nyitható, majd kioldás után az alszárny is nyithatóvá válik",
        },

        {
          id: "99",
          name: "Csak árnyékolást szeretnék, ablak vagy ajtó nélkül",
          widthMin: "30",
          widthMax: "320",
          heightMin: "30",
          heightMax: "240",
          picture: "ablak-fix.gif",
          description:
            "Ebbe a kis dobozba kerülhet a termékek leírása, fényképe, amelyek segítik a vásárlót abban hogy a termék kiválasztásakor tájékozódjon.",
        },
      ], // A kiválasztható termékek adatlapjai.

      products: [
        {
          group: " ",
          items: [
            {
              id: "0",
              name: "Válasszon nyílászárót!",
            },
          ],
        },
        {
          group: "ablak",
          items: [
            {
              id: "1",
              name: "Fix ablak",
            },
            {
              id: "2",
              name: "Bukó ablak felső kilinccsel",
            },
            {
              id: "3",
              name: "Két kilincses bukó ablak",
            },
            {
              id: "4",
              name: "Bukó-nyíló ablak",
            },
            {
              id: "5",
              name: "Váltószárnyas nyíló + bukó-nyíló",
            },
            {
              id: "6",
              name: "Tokosztott bukó-nyíló + bukó-nyíló",
            },
          ],
        },
        {
          group: "erkélyajtó",
          items: [
            {
              id: "7",
              name: "Bukó-nyíló erkélyajtó",
            },

            {
              id: "8",
              name: "Váltószárnyas nyíló + bukó-nyíló erkélyajtó",
            },

            {
              id: "9",
              name: "Egyszárnyú toló-bukó erkélyajtó",
            },

            {
              id: "10",
              name: "Kétszárnyú toló-bukó erkélyajtó",
            },
          ],
        },
        {
          group: "bejárati ajtó",
          items: [
            {
              id: "11",
              name: "Befelé nyíló bejárati ajtó, teli üvegezéssel",
            },

            {
              id: "12",
              name: "Befelé nyíló, kétszárnyú bejárati ajtó, teli üvegezéssel",
            },
          ],
        },
        {
          group: "árnyékolás",
          items: [
            {
              id: "99",
              name: "Csak árnyékolást szeretnék, ablak vagy ajtó nélkül",
            },
          ],
        },
      ], // A Nyílászáró rovatban megjelenő select group és tételek. Szerepelniük kell a kiválasztható tétel tömbben is a termékeknek!
      shadings: [
        {
          group: "redőny",
          items: [
            {
              id: "0",
              name: "árnyékolás nélkül kérem",
            },
            {
              id: "1",
              name: "műanyag redőny",
            },
            {
              id: "2",
              name: "műanyag redőny + szúnyogháló",
            },
            {
              id: "3",
              name: "alumínium redőny",
            },
            {
              id: "4",
              name: "alumínium redőny + szúnyogháló",
            },
          ],
        },

        {
          group: "rovarháló",
          items: [
            {
              id: "5",
              name: "fix szúnyogháló",
            },
            {
              id: "6",
              name: "rolós szúnyogháló",
            },
            {
              id: "7",
              name: "nyíló szúnyogháló ajtó",
            },
            {
              id: "8",
              name: "oldalra húzható szúnyogháló",
            },
          ],
        },
      ], // Árnyékolás lehetőségei.
      ledges: [
        {
          id: "0",
          name: "párkány nélkül kérem",
        },
        {
          id: "1",
          name: "belső PVC párkány",
        },
        {
          id: "2",
          name: "külső PVC párkány",
        },
        {
          id: "3",
          name: "kívül-belül PVC párkány",
        },
      ], // Párkény lehetőségei.
      installations: [
        {
          id: "0",
          name: "beépítés nélkül kérem",
        },
        {
          id: "1",
          name: "új, üres nyílásba",
        },
        {
          id: "2",
          name: "régi ablak cseréje",
        },
      ], // Beépítés lehetőségei.

      //colors: ["white", "#b3afaf26"],

      userData: {
        userName: "",
        userEmail: "",
        userPhone: "",
        userCity: "",
        userMessage: "",
      }, // Felhasználó adatait tároló tömb.

      emptyRow: [
        {
          name: "",
          widthMin: "0",
          widthMax: "0",
          heightMin: "0",
          heightMax: "0",
          description: "",
          sorazonosito: "0",
        },
      ], // Üres elem amellyel növeljük a tömböt ha hozzáadunk egy elemet.

      selectedRows: [
        {
          name: "",
          widthMin: "0",
          widthMax: "0",
          heightMin: "0",
          heightMax: "0",
          description: "",
          sorazonosito: "0",
        },
        {
          name: "",
          widthMin: "0",
          widthMax: "0",
          heightMin: "0",
          heightMax: "0",
          description: "",
          sorazonosito: "0",
        },
      ], // Kiválasztott termékek tömbje, melyet a végén átadunk.
    };
  },

  mounted() {
    //this.$refs.input.scrollIntoView({ behavior: "smooth" });
  },

  updated() {},

  methods: {
    showErrorModal(header, body) {
      this.errorBoxhead = header;
      this.errorBoxbody = body;
      this.isErrorModalOpen = true;
    },
    closeErrorModal() {
      this.isErrorModalOpen = false;
    },

    minMaxInputCheck(min, max, variable, uniqueInputId) {

      const items = document.getElementById(uniqueInputId);

      if (Number(variable) < min || Number(variable) > max) {
        //console.log("Most:" + " " + variable + " " + min + " " + max);
        items.classList.add("error-theme");
      } else {
        items.classList.remove("error-theme");
      }
    }, // Ha a megadott érték határértéken kívül van, az adott elemet az error-css -sel kiemeli.

    moveToBottom() {
      var ref = document.getElementById("myElement");

      setTimeout(function () {
        ref.scrollIntoView({
          left: 0,
          bottom: 100,
          block: "end",
          behavior: "smooth",
        });
      }, 100);
    }, // A függvény segítségével mindig a hozzáadott termékek aljára görget.

    selectedWindow(event, row) {

      // A függvény kiválasztja a termékek közül ID alapján a szükséges tételt az adataival együtt és feltölti a sort alapadatokkal.


       // KULCSFÜGGVÉNY!!!!
       // Az aktuális kosártömbelembe a függvény beleteszi a letárolt adatok közül az ablak alapadatait,
       // majd a kötések miatt belekerül a kiválasztott méret is, AMELY NEM HAT VISSZA a tömbben tárolt termékadatokra!!!!!
       
        // 1. Ez a rész kikeresi a választott termék indexe alapján azadatokat a termékeket tartalmazó tömbböl. 
        // this.productData[
        // this.productData.findIndex((p) => p.id == event.target.value)
        // ]

        // 2.  JSON.parse(JSON.stringify rész ki-be csomagolja az adatokat, hogy ne legyen reaktivitás a kosártömbelem és a terméktömb között!
        // Ez a szívás volt ami miatt keveredtek az adatok!

       this.selectedRows[row] = JSON.parse(JSON.stringify(
        this.productData[
          this.productData.findIndex((p) => p.id == event.target.value)
        ]
       ));


    

      this.message[row] = this.selectedRows[row].description;

     

      if (this.selectedRows[row].picture === "") {
        this.messagePic[row] = this.noPicUrl;
      } else {
        this.messagePic[row] =
          this.domainPicUrl +
          this.selectedRows[row].picture;
      }

      this.values["dynamic-width-" + row] = this.selectedRows[row].widthMin;
      this.values["dynamic-height-" + row] = this.selectedRows[row].heightMin;
      this.values["dynamic-quantity-" + row] = this.defaultQuantity;

    
      this.selectedRows[row]["selectedWidth"] =
        this.values["dynamic-width-" + row];


      this.selectedRows[row]["selectedHeight"] =
        this.values["dynamic-height-" + row];
      this.selectedRows[row]["selectedQuantity"] =
        this.values["dynamic-quantity-" + row];

        this.selectedRows[row]["sorazonosito"] = row;


       

        
      this.values["dynamic-shading-" + row] = "árnyékolás nélkül kérem";
      this.values["dynamic-ledges-" + row] = "párkány nélkül kérem";
      this.values["dynamic-installation-" + row] = "beépítés nélkül kérem";

      //this.values['disabled' + row] =false;
      //this.disabledValues = false;

      document.getElementById("dynamic-width-" + row).disabled = false;
      document.getElementById("dynamic-height-" + row).disabled = false;
      document.getElementById("dynamic-quantity-" + row).disabled = false;

      document.getElementById("dynamic-shading-" + row).disabled = false;
      document.getElementById("dynamic-ledges-" + row).disabled = false;
      document.getElementById("dynamic-installation-" + row).disabled = false;

       
      // window.alert("Már átírta az adatokat? : " + row);
    },

    selectedData(event, row, variableName) {
      //this.teszt = this.products[event.target.value];
      //window.alert('Én írom át! ' + event.target.value + ' sor ' + row );
      this.selectedRows[row][variableName] = event.target.value;
    },

    add: function () {
      //window.alert('Hozzáad:' + this.count);
      if (
        this.selectedRows[this.count]["id"] > 0 &&
        Number(this.selectedRows[this.count]["selectedWidth"]) >=
          Number(this.selectedRows[this.count]["widthMin"]) &&
        Number(this.selectedRows[this.count]["selectedWidth"]) <=
          Number(this.selectedRows[this.count]["widthMax"]) &&
        Number(this.selectedRows[this.count]["selectedHeight"]) >=
          Number(this.selectedRows[this.count]["heightMin"]) &&
        Number(this.selectedRows[this.count]["selectedHeight"]) <=
          Number(this.selectedRows[this.count]["heightMax"])
      ) {
        //console.log(this.selectedRows[this.count]["selectedWidth"], this.selectedRows[this.count]["selectedHeight"], this.selectedRows[this.count]["selectedQuantity"]);

        this.selectedRows.push(this.emptyRow);

        this.count++;
        this.moveToBottom();
      } else {
        this.showErrorModal(
          "Kitöltési probléma",
          "Kérjük hogy a hiányzó paramétereket adja meg, vagy a megjelölt mezőben lévő nem megfelelő értékeket javítsa ki!"
        );
      }
    },
    remove(deleteIndex) {
      //window.alert("Töröld: " + deleteIndex + " elemszam:" +  this.count);

      if (deleteIndex == 1) {
        //window.alert("Töröld: " + deleteIndex);
        this.count = 1;
        this.selectedRows.splice(deleteIndex, 1);
        this.selectedRows.push(this.emptyRow);

        this.message[deleteIndex] = "";
        this.messagePic[deleteIndex] = "";

        this.values["this.offer-flex-select-1"] = 0;
        delete this.values["dynamic-width-" + deleteIndex];
        delete this.values["dynamic-height-" + deleteIndex];
        this.values["dynamic-quantity-" + deleteIndex] = 1;
      } else if (deleteIndex > 1) {
        this.selectedRows.splice(deleteIndex, 1);
        this.message[deleteIndex] = "";
        this.messagePic[deleteIndex] = "";
        delete this.values["dynamic-width-" + deleteIndex];
        delete this.values["dynamic-height-" + deleteIndex];
        delete this.values["dynamic-quantity-" + deleteIndex];

        this.values["dynamic-shading-" + deleteIndex] =
          "árnyékolás nélkül kérem";
        this.values["dynamic-ledges-" + deleteIndex] = "párkány nélkül kérem";
        this.values["dynamic-installation-" + deleteIndex] =
          "beépítés nélkül kérem";

        this.count--;
      }

      // if (this.count < 2) {
      //   this.count = 1;
      //   this.selectedRows[1]["id"] = "0";
      // } else {
      //   this.values["dynamic-field-" + this.count] = "";
      //   delete this.values["dynamic-field-" + this.count];

      //   this.selectedRows.splice(this.count, 1);

      //   this.count--;
      // }

      this.moveToBottom();

      //this.selectedRows[row]=(this.emptyRow);
    },
    submit: function () {
      //  for (var key of Object.keys(this.values)) {
      //   console.log(key + " => " + this.values[key]);
      // }
    },

    userCheck(requiredLength, variableId) {
      const items = this.values[variableId];

      const item = document.getElementById(variableId);

      //console.log(items.length);

      if (items.length < Number(requiredLength)) {
        item.classList.add("error-theme");
      } else {
        item.classList.remove("error-theme");
      }
    },

    goToNextOffer(url){
      window.location.href = String(url);
    },

    signUpButtonPressed() {
      this.checkSelectedRowValue = 0;

      this.selectedRows.forEach((element) => {
        if (typeof element.id === "undefined") {
          //console.log(element.id);
        } else {
          this.checkSelectedRowValue = +element.widthMin;
          //console.log(element.id);
        }
      });

      //console.log("Sign In Button Pressed");

      this.userData.userName = this.values["formUserName"];
      this.userData.userEmail = this.values["formUserEmail"];
      this.userData.userPhone = this.values["formUserPhone"];
      this.userData.userCity = this.values["formUserCity"];
      this.userData.userMessage = this.values["formUserMessage"];

      if (
        typeof this.values["formUserName"] === "undefined" ||
        typeof this.values["formUserEmail"] === "undefined" ||
        typeof this.values["formUserPhone"] === "undefined" ||
        typeof this.values["formUserCity"] === "undefined"
      ) {
        //str.length > 3
        this.showErrorModal(
          "Hiba a személyes adatok megadása során!",
          "Kérjük hogy a kapcsolatfelvételhez szükséges minden kötelező adatot adjon meg, vagy a megjelölt mezőben lévő nem megfelelő értékeket javítsa ki!"
        );
      } else {
        if (
          this.values["formUserName"].length < 3 ||
          this.values["formUserEmail"].length < 6 ||
          this.values["formUserPhone"].length < 6 ||
          this.values["formUserCity"].length < 2
        ) {
          this.showErrorModal(
            "Hiba a személyes adatok megadása során!",
            "Kérjük hogy a kapcsolatfelvételhez valós kapcsolatfelvételi adatokat adjon meg, vagy a megjelölt mezőben lévő nem megfelelő értékeket javítsa ki!"
          );
        } else {
          //this.userData.filename = Math.ceil(Math.random()*1000000);

          if (typeof this.selectedRows[this.count]["id"] === "undefined") {
            //window.alert('Le kell vágnom!' + this.count);

            if (this.count > 1) {
              //window.alert('Bakker lefutok' + this.count);
              this.selectedRows.splice(this.count);
              this.count--;
            }

            // if (this.count > 1) {
            //this.count--;
            //   }
            //  }else{
            //    window.alert('Shiftelek' + this.count);
            //    this.selectedRows.shift;
            //  }
          }




          //------ Ez a post küldő
          if (this.checkSelectedRowValue >= 1) {
            
             axios.post(
               String(this.postDataUrl),
             {
                windowForm: this.selectedRows,
                userData: this.userData,
             }
            ).finally(() => {
              this.goToNextOffer(this.finishUrlAfterOrder);
            })
              



          //  console.log("URL kint:", this.selectedRows);
            
            

          } else {
            //window.alert(this.count + " sor. Hibát kell ütnöm!" + this.checkSelectedRowValue);
            this.showErrorModal(
              "A termékválsztás kötelező!",
              "Kérjük hogy valamilyen terméket válasszon ki."
            );
          }

          // window.location.href = "https://pongiablak.powergent.com/succesful.php?file=" + this.userData.filename;
        }
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  flex-direction: column;
}

select,
input * {
  all: revert;
  color: unset;
}

input[type="number"]::-webkit-inner-spin-button {
  opacity: 1;
}

.btn {
  width: 50%;
  background: #3f51b5;
  color: white;
  border: 0;
  padding: 15px;
  border-radius: 5px;
  height: auto;
  margin-top: 10px;
}

.btn-delete {
  width: auto;
  background: red;
  color: white;
  border: 0;
  padding: 4px;
  border-radius: 5px;
}

.btn-add {
  width: 50%;
  background: green;
  color: white;
  border: 0;
  padding: 15px;
  border-radius: 5px;
}

.btn-remove {
  width: 50%;
  background: red;
  color: white;
  border: 0;
  padding: 15px;
  border-radius: 5px;
}

.wrapper {
  min-width: 400px;
  max-width: 800px;
  margin: 40px auto;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 10px 40px 0px rgba(47, 47, 47, 0.1);
}

.offer-row {
  padding: 10px;
}

.offer-row-ledger {
  color: #232c3d;
  display: block;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
}

.offer-flex-parent {
  display: flex;
}

.offer-flex-item {
  padding: 0.5em;
  width: 33%;
}

.offer-flex-select {
  padding: 0.5em;
  width: 100%;
}

.offer-div {
  border: 0px solid #000;
  width: 55%;
}

.offer-flex-personal {
  width: 50%;
}

.offer-div h4,
.offer-flex-item h4,
.offer-flex-select h4,
.offer-flex-personal h4,
.offer-flex-personal-message h4 {
  color: darkgrey;
  text-align: left;
  font-size: 12px;
  margin-bottom: 5px;
}

.offer-select,
.shadings-select,
.ledges-select,
.installation-select {
  width: 100%;
  padding: 10px;

  border-radius: 5px;
  border: 1px solid lightgrey;
  background: none;
  color: black;

  -webkit-appearance: menulist-button; /* Safari and Chrome */
  -moz-appearance: menulist-button; /* Firefox */
  appearance: menulist-button;
}

.offer-width,
.offer-height,
.offer-quantity {
  padding: 11px;
  width: 90%;
  border-radius: 5px;
  border: 1px solid lightgrey;
  background: none;
  color: black !important;

  -webkit-appearance: menulist-button; /* Safari and Chrome */
  -moz-appearance: menulist-button; /* Firefox */
  appearance: menulist-button;
}

input[type="text"],
input[type="email"] {
  padding: 10px;
  margin: 0px;
  display: block;
  border-radius: 5px;
  border: 1px solid lightgrey;

  width: 92%;
  color: black;
}
.offer-flex-personal-message {
  width: 96%;
}

.offer-flex-personal-message textarea {
  padding: 10px;
  margin: 0px;
  display: block;
  border-radius: 5px;
  border: 1px solid lightgrey;
  background: none;
  width: 100%;
  color: black;
}

input[type="text"]:focus {
  outline: none;
}

.controls {
  width: 400px;
  margin: 15px auto;
  font-size: 12px;
}

#remove_fields {
  margin-left: 10px;
}
.controls a i.fa-minus {
  margin-right: 5px;
}

a {
  color: black;
  text-decoration: none;
}

h1 {
  text-align: center;
  font-size: 48px;
  color: #232c3d;
}

.error-theme {
  background-color: #ffcdd2 !important;
}

.offer-short-descr {
  padding-top: 10px;
  padding-left: 10px;
  text-align: left;
  font-size: 12px;
  font-style: italic;
  line-height: 140%;
}

input {
  font-size: 14px !important;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px !important;
  color: #c0bdbd !important;
}

textarea.offer-personal {
  height: 100px;
}
</style>
